#revised-proposal-body {
  .font-lateral {
    font-family: lateral;
  }

  .text-underline {
    text-decoration: underline;
  }

  .text-gray {
    color: #8481a2;
  }

  // SWAL
  .swal2-title,
  .swal2-html-container {
    color: #201a56;
  }

  .swal2-confirm {
    background-color: #201a56;
    color: #fff;
    font-size: 20px;
    border-radius: 20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    font-weight: bold;
  }

  .swal2-confirm:focus {
    border: 0;
  }
}
